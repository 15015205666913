

































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
    SildeBanner: () => import('@/components/base/pc/InsBanner.vue')
  }
})
export default class InsOrderList extends Vue {
  currentPage: number = 1;
  pagesize: number = 12;
  OrderListLength: any = '';
  SearchInput: any = '';
  OrderList:any[]=[];
  NewArarry:any[]=[];
  get SearchData () {
    let that = this;
    return that.NewArarry.filter(item => {
      if (!that.SearchInput) {
        return that.NewArarry;
      }
      return item.OrderItems[0].Product.Name.includes(that.SearchInput);
    });
  }
  async created () {
    let that = this;
    this.getOrder().then(() => {
      this.NewArarry = that.OrderList.filter(item => {
        return that.NewArarry;
      });
      this.$HiddenLayer();
    });
    this.OrderListLength = this.SearchData.length;
  }

  getOrder () {
    let that = this;
    return that.$Api.order.getPageOrder(10, 1).then((result) => {
      that.OrderList = result.Data;
      this.OrderListLength = result.Data.length;
    });
  }
  ShowDetail (order) {
    // 新窗口打开路由
    let routeData = this.$router.resolve({
      path: '/account/orderDetail',
      name: 'orderDetail',
      params: { id: order.OrderId }
    });
    window.open(routeData.href, '_blank');
  }
  OrderComment (order) {
    this.$router.push({
      path: '/account/orderComment',
      name: 'orderComment',
      params: { id: order.OrderId }
    });
  }
  // 支付打开页面
  PayNow (order) {
    // let routeData = this.$router.resolve({
    //   path: '/payment/' + order.PaymentMethod + '/',
    //   name: 'payment',
    //   params: { id: order.OrderId }
    // });
    // window.open(routeData.href, '_blank');
    let url = '/intimex/pay/' + order.PaymentMethod + '/IS/' + order.OrderId;
    window.open(url, '_blank');
  }

  currentChange (currentPage) {
    // 改变当前页
    this.currentPage = currentPage;
  }
  // 点击上一页和下一页的时事件
  handleSizeChange (psize) {
    this.pagesize = psize;
  }
}
