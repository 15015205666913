





























































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Form as ElForm, Input } from 'element-ui';
import Address from '@/model/address';
import { Country } from '@/model/country';
import { Province } from '@/model/province';
@Component
export default class InsDeliveryAddress extends Vue {
  CountryId: number = 786;
  countryList: any[] = [];
  private province!:Province;
  provinceList: any[] = [];
  validator: any = '';
  addr: any = {};
  value: any = '';
  private addressList:Address[] = [];
  addListlength:any='';
  private AddForm = {
    FirstName: '',
    LastName: '',
    Phone: '',
    Mobile: '',
    PostalCode: '',
    CountryId: '',
    Province: '',
    Address: '',
    Default: ''
  }
  get newaddress () {
    return {
      FirstName: [
        {
          required: true,
          message: this.$t('MemberInfo.EnterUserName'),
          trigger: 'blur'
        }
      ],
      LastName: [
        {
          required: true,
          message: this.$t('MemberInfo.EnterUserLastName'),
          trigger: 'blur'
        }
      ],
      Phone: [
        {
          required: true,
          message: this.$t('MemberInfo.EnterUserPhone'),
          trigger: 'blur'
        }
      ],
      Mobile: [
        {
          required: true,
          message: this.$t('MemberInfo.EnterUserPhone'),
          trigger: 'blur'
        }
      ],
      Country: [
        {
          required: true,
          message: this.$t('Address.Country'),
          trigger: ['blur', 'change']
        }
      ],
      Province: [
        {
          required: true,
          message: this.$t('Address.Province'),
          trigger: ['blur', 'change']
        }
      ],
      Address: [
        {
          required: true,
          message: this.$t('Address.Address'),
          trigger: 'blur'
        }
      ]
    };
  }

  //   加载国家列表
  getCountry () {
    let _this = this;
    this.$Api.delivery.getCountry().then((result) => {
      _this.countryList = result.data;
      console.log(result, 'result');
    });
  }

  //   加载对应省列表
  getProvince (num) {
    let _this = this;
    this.$Api.delivery.getProvince(num).then((result) => {
      _this.provinceList = result.data;
      console.log(_this.provinceList, 'result');
    });
  }

  selectCountry (cid) {
    var countryId = cid;
    console.log(countryId, 'countryId');
    this.getProvince(countryId);
  }

  // 加载地址列表
  getAddress () {
    let _this = this;
    this.$Api.delivery.getAddress().then((result) => {
      _this.addressList = result.data;
      this.$HiddenLayer();
    });
  }
  // 删除对应的地址
  removeAddr (cid) {
    let _this = this;
    var addId = cid;
    this.$Api.delivery.removeAddress(addId).then((result) => {
      this.addressList.pop();
      _this.$message({
        message: this.$t('MyFavorite.RemoveSuccess') as string,
        type: 'success'
      });
      console.log(result, 'result');
    });
  }

  // 重置
  reset () {
    this.provinceList = [];
    this.addr = {};
  }
  // 编辑地址
  editAddr (index) {
    let _this = this;
    Object.keys(this.AddForm).forEach((element) => {
      this.AddForm[element] = this.addressList[index][element];
    });
    this.AddForm['DeliveryId'] = this.addressList[index].DeliveryId;
    this.$Api.delivery.getProvince(this.addressList[index].CountryId).then((result) => {
      _this.provinceList = result.data;
    });
  }
  //   保存地址
  private saveAddress (formName) {
    let _this = this;
    (this.$refs.AddForm as ElForm).validate(valid => {
      if (valid) {
        this.$Api.delivery.saveAddress(this.AddForm).then((result) => {
          this.getAddress();
          _this.$message({
            message: '保存成功',
            type: 'success'
          });
        });
      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }
  mounted () {
    this.getAddress();
    this.getCountry();
  }
@Watch('this.addListlength')
  onCountryChange (n, o) {

  }
}
